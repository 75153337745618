const navigation = document.querySelector('.o-masthead__primary-nav')
const menuItems = document.querySelectorAll('.has-children > .site-menu__link')
const submenuTitles = document.querySelectorAll('.submenu__column__title')
const submenuClose = document.querySelectorAll('.submenu__close')
const mobileMenuClose = document.querySelector('.site-menu__mobile-close')

const background = document.querySelector('.site-menu__background')
const mobileToggle = document.querySelector('.js-nav-toggle')

const searchButton = document.querySelector('.o-masthead__search-link')
const search = document.querySelector('.site-menu__search')

var mobileMenuOpen = false


// General
function openSubmenu() { // opens the submenu for the main menu items
    var parentItem = this.parentElement
    var isOpen = parentItem.classList.contains('active')
    const activeSubmenu = document.querySelectorAll('.has-children.active')

    if (!isOpen) {
		activeSubmenu.forEach(function(el) {
			el.classList.remove('active')
			el.classList.remove('state')
		})

        closeSearch()
        animateIn(parentItem, 200)
        animateIn(background)
    }

    if (isOpen) { 
        animateOut(parentItem, 500) 
        animateOut(background, 500)
    }
}

function closeSubmenu() {
    const activeSubmenu = document.querySelectorAll('.has-children.active')
    activeSubmenu.forEach(function(el) {
        animateOut(el, 500)
    })
    animateOut(background, 500)
}

function toggleSearch() {
    const isActive = this.classList.contains('active')
    const activeMenus = document.querySelectorAll('.has-children.active')

    if (!isActive) {
		activeMenus.forEach(function(el) {
			el.classList.remove('active')
			el.classList.remove('state')
		})

        searchButton.classList.add('active')
        animateIn(search, 200)
        animateIn(background)
    }

    if (isActive) { 
        closeSearch()
        animateOut(background, 500)
    }
}

function closeSearch() {
    searchButton.classList.remove('active')
    animateOut(search, 500) 
}

// For mobile
function openSubmenuChild() {
    var parentItem = this.parentElement
    var isOpen = parentItem.classList.contains('active')

    if (!isOpen) { 
        animateIn(parentItem, 0) 
    } else {
        animateOut(parentItem, 0) 
    }
}

function toggleMobileMenu() {
    if (!mobileMenuOpen) {
        mobileMenuOpen = true
        animateIn(navigation, 200)
        animateIn(background)
    } else {
        animateOut(navigation, 500)
        animateOut(background, 500)
        mobileMenuOpen = false
    }   
}

function closeMobileMenu() {
    if (mobileMenuOpen) {
        animateOut(navigation, 500)
        animateOut(background, 500)
        mobileMenuOpen = false  
    }
}

// Event listeners
menuItems.forEach(function(el) {
	el.addEventListener('click', openSubmenu);
	el.addEventListener("click", function(event){
		event.preventDefault()
	});
});

submenuTitles.forEach(function(el) {
    el.addEventListener('click', openSubmenuChild);
})

submenuClose.forEach(function(el) {
    el.addEventListener('click', closeSubmenu)
})

mobileToggle.addEventListener('click', toggleMobileMenu)
mobileMenuClose.addEventListener('click', toggleMobileMenu)

searchButton.addEventListener('click', toggleSearch)

background.addEventListener('click', () => {
    closeSubmenu()
    closeSearch()
    closeMobileMenu()
})

// detect esc key
window.addEventListener('keydown', event => {
	const escKey = (event.key === 'Escape' || event.keyCode === 27)
	const isOpen = document.querySelector('.active') !== null
	if (escKey && isOpen) {
        closeSubmenu()
        closeSearch()
    }
})


// Animation and timeouts
function animateIn(el, delay = 200) {
	el.classList.add('state')
	setTimeout(() => {
		el.classList.add('active') // display none
	}, delay); // transform - 100px
}

function animateOut(el, delay = 200) {
	el.classList.remove('active') // display none
	setTimeout(() => {
		el.classList.remove('state')
	}, delay); // transform - 100px
}